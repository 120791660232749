var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{staticClass:"main"},[_c('v-row',{attrs:{"no-gutters":"","justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"8"}},[_c('div',{staticClass:"d-flex justify-center"},[_c('router-link',{attrs:{"to":"/"}},[_c('v-img',{attrs:{"height":"250","width":"250","src":require('../assets/images/logo.png')}})],1)],1),_c('v-card',{staticClass:"mx-auto",staticStyle:{"margin-top":"-100px"},attrs:{"max-width":"520","flat":"","color":"transparent"}},[_c('v-card-text',[_c('div',{staticClass:"d-flex justify-center"},[_c('v-btn-toggle',{staticClass:"my-4 login-toggle",attrs:{"active-class":"login-selected","center":"","mandatory":"","rounded":""},model:{value:(_vm.provider),callback:function ($$v) {_vm.provider=$$v},expression:"provider"}},_vm._l((_vm.items),function(item){return _c('v-btn',{key:item,staticClass:"text-normal login-toggle",attrs:{"text":"","value":item.value,"width":"100","exact":""}},[_vm._v(_vm._s(item.text))])}),1)],1),_c('v-form',{staticClass:"col-10 mx-auto mb-2",on:{"submit":function($event){$event.preventDefault();return _vm.register.apply(null, arguments)}}},[_c('label',{staticClass:"caption"},[_vm._v("EMAIL")]),_c('v-text-field',{attrs:{"append-icon":"mdi-email-outline","type":"email","error-messages":_vm.$store.getters[(_vm.provider + "/errorsGetters")]
                  ? _vm.$store.getters[(_vm.provider + "/errorsGetters")][
                      'email'
                    ]
                  : '',"solo":""},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('label',{staticClass:"caption"},[_vm._v("PASSWORD")]),_c('v-text-field',{attrs:{"solo":"","append-icon":_vm.pass1 ? 'mdi-eye-off-outline' : 'mdi-eye-outline',"error-messages":_vm.$store.getters[(_vm.provider + "/errorsGetters")]
                  ? _vm.$store.getters[(_vm.provider + "/errorsGetters")][
                      'password'
                    ]
                  : '',"type":_vm.pass1 ? 'text' : 'password',"hide-details":""},on:{"click:append":function($event){_vm.pass1 = !_vm.pass1}},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),_c('div',{staticClass:"text-right my-5"},[_c('v-btn',{staticClass:"button caption",attrs:{"small":"","text":""}},[_vm._v("Forgot Password")])],1),(!_vm.loading)?_c('v-btn',{attrs:{"type":"submit","dark":"","x-large":"","rounded":"","color":"primary","block":""}},[_vm._v("login")]):_c('v-btn',{attrs:{"rounded":"","dark":"","x-large":"","color":"primary","block":""}},[_vm._v("logging....")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }