<template>
    <!-- <v-app style="background-color: #4EA058;
    background-image: linear-gradient(to top right, #4EA058 , #FFC624);"> -->
    <!-- :style="{
        backgroundImage: 'url(' + require(`../assets/images/bg.png`) + ')',
      }"
      style="background-size: 100vw 100vh" -->
  <v-app class="main">
    <!-- <v-container fill-height>
      <v-row justify="center" align="center">
        <v-col cols="12" xl="6" lg="8">
          <v-card
            outlined
            max-width="400"
            class="mx-auto"
          >
            <v-card-text>
              <div class="d-flex pa-1">
                <router-link to="/">
                  <v-avatar tile>
                    <v-img :src="require('../assets/images/logo.png')"></v-img>
                  </v-avatar>
                </router-link>
                
                <div class="d-flex flex-column ml-3">
                  <div style="font-size: 17px; font-weight: 600" class="black--text">
                    Philippine Society of General Surgeons
                  </div>
                  <div class="caption primary--text">
                    Login as
                  </div>
                </div>
                  
              </div>
              
              <div class="d-flex justify-center mb-5 mt-3">
                <v-btn-toggle
                  active-class="login-selected"
                  center
                  mandatory
                  v-model="provider"
                  class="my-4 login-toggle"
                >
                  <v-btn
                    text
                    class="text-normal login-toggle"
                    value="student"
                    width="100"
                    >User</v-btn
                  >
                  <v-btn
                    text
                    class="text-normal login-toggle"
                    value="teacher"
                    >Instructor</v-btn
                  >
                  <v-btn
                    text
                    class="text-normal login-toggle"
                    value="admin"
                    >&nbsp;&nbsp; Admin &nbsp;&nbsp;</v-btn
                  >
                </v-btn-toggle>
              </div>
              <v-form @submit.prevent="register" class="mb-2">
                <label class="caption">EMAIL</label>
                <v-text-field
                  append-icon="mdi-email-outline"
                  v-model="form.email"
                  type="email"
                  :error-messages="
                    $store.getters[`${provider}/errorsGetters`]
                      ? $store.getters[`${provider}/errorsGetters`][
                          'email'
                        ]
                      : ''
                  "
                  solo
                />
                <label class="caption">PASSWORD</label>
                <v-text-field
                  solo
                  :append-icon="
                    pass1 ? 'mdi-eye-off-outline' : 'mdi-eye-outline'
                  "
                  @click:append="pass1 = !pass1"
                  v-model="form.password"
                  :error-messages="
                    $store.getters[`${provider}/errorsGetters`]
                      ? $store.getters[`${provider}/errorsGetters`][
                          'password'
                        ]
                      : ''
                  "
                  :type="pass1 ? 'text' : 'password'"
                  hide-details
                />
                <div class="text-right my-5">
                  <v-btn small class="button caption" text>Forgot Password</v-btn>
                </div>
                <v-btn
                  v-if="!loading"
                  type="submit"
                  dark
                  x-large
                  color="primary"
                  block
                  >login</v-btn
                >
                <v-btn v-else dark x-large color="primary" block
                  >logging....</v-btn
                >
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container> -->
    <v-row no-gutters justify="center" align="center">
      <v-col cols="8" class="">
        <div class="d-flex justify-center">
          <router-link to="/">
            <v-img height="250" width="250" :src="require('../assets/images/logo.png')"></v-img>
          </router-link>
        </div>
        <v-card max-width="520" class="mx-auto" flat color="transparent" style="margin-top:-100px">
          <v-card-text>
            <div class="d-flex justify-center">
              <v-btn-toggle active-class="login-selected" center mandatory rounded v-model="provider" class="my-4 login-toggle" >
                <v-btn
                  v-for="item in items"
                  :key="item"
                  text
                  class="text-normal login-toggle"
                  :value="item.value"
                  width="100"
                  exact
                  >{{item.text}}</v-btn
                >
              </v-btn-toggle>
            </div>
            <v-form @submit.prevent="register" class="col-10 mx-auto mb-2">
              <label class="caption">EMAIL</label>
              <v-text-field
                append-icon="mdi-email-outline"
                v-model="form.email"
                type="email"
                :error-messages="
                  $store.getters[`${provider}/errorsGetters`]
                    ? $store.getters[`${provider}/errorsGetters`][
                        'email'
                      ]
                    : ''
                "
                solo
              />
              <label class="caption">PASSWORD</label>
              <v-text-field
                solo
                :append-icon="
                  pass1 ? 'mdi-eye-off-outline' : 'mdi-eye-outline'
                "
                @click:append="pass1 = !pass1"
                v-model="form.password"
                :error-messages="
                  $store.getters[`${provider}/errorsGetters`]
                    ? $store.getters[`${provider}/errorsGetters`][
                        'password'
                      ]
                    : ''
                "
                :type="pass1 ? 'text' : 'password'"
                hide-details
              />
              <div class="text-right my-5">
                <v-btn small class="button caption" text>Forgot Password</v-btn>
              </div>
              <v-btn
                v-if="!loading"
                type="submit"
                dark
                x-large rounded
                color="primary"
                block
                >login</v-btn
              >
              <v-btn rounded v-else dark x-large color="primary" block
                >logging....</v-btn
              >
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
      <!-- <v-col cols="4" class="">
        <v-sheet color="#3D85D5" height="100vh">

        </v-sheet>
      </v-col> -->

    </v-row>
  </v-app>
</template>

<script>
export default {
  data: () => ({
    form: { email: "", password: "" },
    provider: "",
    pass1: false,
    loading: false,
    items: [
      { text: 'USER', value: 'student' },
      { text: 'TEACHER', value: 'teacher' },
      { text: 'ADMIN', value: 'admin' },
    ]

  }),
  methods: {
    register() {
      this.loading = true;
      this.$store
        .dispatch(`${this.provider}/loginAction`, this.form)
        .then(() => {
          this.loading = false;
        });
    },
  },
};
</script>